import React from "react"

const Pricetab = () => {
    return(
        <div className="price-tab">
        <div className="container">
            <div className="row-main">
                <div className="main-text"> Pricing. </div>
                <div className="sub-text">  Select the plan that fits your processes.  </div>
            </div>
            <div className="row-feat">
                <div className="column-feat">
                    <div className="title">Basic.</div>
                    <div className="title2">80EU <span>per driver per month</span> </div>
                    <div>Standard route optimization for any vehicle and any waste type. </div>
                    <div>Standard invoicing and customer database. </div>
                    <div>API for exporting data on the collected waste streams and driven routes.</div>
                    <div> Make a note of all relevant pickup information at each address.  </div>
                </div>
                <div className="column-feat">
                    <div className="title">Pro.</div>
                    <div className="title2">120EU <span>per driver per month</span> </div>
                    <div>Functionalities of the Basic plan. </div>
                    <div>Dashboard with historical statistics about the collected waste, client and driver requests and driven routes. </div>
                    <div>Dashboard with a real-time proces monitor.</div>
                    <div>Automatically resolve client- and driver requests.  </div>
                </div>
                <div className="column-feat">
                    <div className="title">Enterprise.</div>
                    <div className="title2">Custom</div>
                    <div>Functionalities of the Pro plan. </div>
                    <div>Machine learning engine for waste stream prediction.</div>
                    <div>Data-driven route optimization based on expected waste, crowdedness and recycling options.</div>
                    <div>Personalized customer management, invoicing and database.  </div>
                    <div>Full onboarding and training sessions.</div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Pricetab