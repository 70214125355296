import React from "react"
import "../styles/styles.scss"

import Header from "../components-en/header"
import Demo from "../components-en/demo"
import Footer from "../components-en/footer"
import Pricetab from "../components-en/pricetab"

import {Helmet} from 'react-helmet'

const IndexPage = () => (
    <div>
      <Helmet>
        <html lang="en" />
        <title>Skialabs - Pricing.</title>
        <description>Prices of the smart waste platform.</description>
      </Helmet>
      <Header />
      <Pricetab />
      <Demo />
      <Footer />
    </div>
)

export default IndexPage